import { IVersion, IVersionFragment } from '@/models';

const versions: IVersionFragment[] = [
  {
    id: "fiat-argo-10",
    name: "argo-1.0",
    slug: "argo10",
    nameParts: {
      first: "argo",
      second: "1.0 manual",
    },
    short: "Argo 1.0",
    details: [
      ["Ar-condicionado", "ar-condicionado-digital"],
      ["Direção elétrica progressiva", "direcao-eletrica-progressiva"],
      ["Alarme com | chave canivete", "alarme-chave-canivete"],
    ],
  },
  {
    id: "fiat-argo-drive-10-manual",
    name: "argo-1.0-drive",
    slug: "drive10",
    nameParts: {
      first: "drive",
      second: "1.0 Manual",
    },
    short: "Drive 1.0",
    details: [
      ["Central | multimídia 7”", "central-multimidia"],
      ["Comandos | no volante", "comandos-volante"],
      ["Sensor | de pressão", "sensor-pressao-pneus"],
    ],
  },
  {
    id: "fiat-argo-trekking-13-manual",
    name: "argo-1.3-trekking",
    slug: "trekking13",
    nameParts: {
      first: "trekking",
      second: "1.3 Manual",
    },
    short: "Trekking 1.3",
    details: [
      ["Suspensão | elevada", "suspensao-elevada"],
      ["Revestimento | em couro ECO", "revestimento-couro"],
      ["Pneus de uso | misto ATR", "pneu-uso-misto"],
    ],
  },
  {
    id: "fiat-argo-drive-13-automatico",
    name: "argo-drive-1.3-automatico",
    slug: "drive13at",
    nameParts: {
      first: "drive",
      second: "1.3 Automático",
    },
    short: "Drive 1.3 AT",
    details: [
      ["Mais potente | e econômico", "motor-firefly-13"],
      ["A/C digital | automático", "ar-condicionado-digital"],
      ["Câmbio aut. | CVT 7 marchas", "cambio-cvt-7-marchas"],
    ],
  },
  {
    id: "fiat-argo-trekking-13-automatico",
    name: "argo-1.3-trakking-automatico",
    slug: "trekking13at",
    nameParts: {
      first: "trekking",
      second: "1.3 Automático",
    },
    short: "Trekking 1.3 AT",
    details: [
      ["Câmbio aut. | CVT 7 marchas", "cambio-cvt-7-marchas"],
      ["LED design", "led-design"],
      ["Hill Holder ", "hill-holder"],
    ],
  },
];

export const dataVersions: IVersion[] = [
  ...versions.map((v) => ({
    ...v,
    fullName: `${v.nameParts.first} ${v.nameParts.second}`,
  })),
];
